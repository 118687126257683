import React from 'react';
import { Divider } from '@components/ui';
import Reader from '.';

export default {
  title: 'BRMS/form/Reader',
  component: Reader,
};

const Template = (args) => (
  <>
    <Reader {...args} />
    <Divider height={100} color={'transparent'} />
  </>
);

export const Default = Template.bind({});

Default.args = {
  upload: {
    label: 'Upload file',
    type: 'file',
    name: 'file_field',
  },
  selects: [
    {
      label: 'Header 1',
      type: 'select',
      name: 'select_field_1',
      options: [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla', checked: true },
      ],
    },
    {
      label: 'Header 2',
      type: 'select',
      name: 'select_field_2',
      options: [
        { value: 'chocolate', label: 'Chocolate', checked: true },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
      ],
    },
  ],
};

const persisted_content = [
  ['0', 'First Name', 'Last Name', 'Gender', 'Country', 'Age', 'Date', 'Id'],
  [
    '1',
    'Dulce',
    'Abril',
    'Female',
    'United States',
    '32',
    '15/10/2017',
    '1562',
  ],
  [
    '2',
    'Mara',
    'Hashimoto',
    'Female',
    'Great Britain',
    '25',
    '16/08/2016',
    '1582',
  ],
  ['3', 'Philip', 'Gent', 'Male', 'France', '36', '21/05/2015', '2587'],
  [
    '4',
    'Kathleen',
    'Hanner',
    'Female',
    'United States',
    '25',
    '15/10/2017',
    '3549',
  ],
  [
    '5',
    'Nereida',
    'Magwood',
    'Female',
    'United States',
    '58',
    '16/08/2016',
    '2468',
  ],
  ['6', 'Gaston', 'Brumm', 'Male', 'United States', '24', '21/05/2015', '2554'],
  ['7', 'Etta', 'Hurn', 'Female', 'Great Britain', '56', '15/10/2017', '3598'],
  [
    '8',
    'Earlean',
    'Melgar',
    'Female',
    'United States',
    '27',
    '16/08/2016',
    '2456',
  ],
  [
    '9',
    'Vincenza',
    'Weiland',
    'Female',
    'United States',
    '40',
    '21/05/2015',
    '6548',
  ],
  [
    '10',
    'Fallon',
    'Winward',
    'Female',
    'Great Britain',
    '28',
    '16/08/2016',
    '5486',
  ],
  [
    '11',
    'Arcelia',
    'Bouska',
    'Female',
    'Great Britain',
    '39',
    '21/05/2015',
    '1258',
  ],
  ['12', 'Franklyn', 'Unknow', 'Male', 'France', '38', '15/10/2017', '2579'],
  [
    '13',
    'Sherron',
    'Ascencio',
    'Female',
    'Great Britain',
    '32',
    '16/08/2016',
    '3256',
  ],
  [
    '14',
    'Marcel',
    'Zabriskie',
    'Male',
    'Great Britain',
    '26',
    '21/05/2015',
    '2587',
  ],
];

export const TableOnly = () => (
  <Reader persisted_content={persisted_content} tableOnly />
);
