import React from 'react';
import PropTypes from 'prop-types';
import Button from '@components/button/button';
import Content from '@components/text/content';
import Base from './base';
import Collection from './collection';

const Tooltip = ({ collection, ...props }) =>
  collection ? <Collection {...props} /> : <Base {...props} />;

Tooltip.propTypes = {
  /** The content of the tooltip, can be another Component */
  content: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
    PropTypes.shape(Content.propTypes),
  ]),
  /** Custom content for the tooltip */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /** The position of the tooltip */
  position: PropTypes.oneOf([
    'top',
    'top-start',
    'top-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'left',
    'left-start',
    'left-end',
    'right',
    'right-start',
    'right-end',
  ]),
  /** The trigger for the tooltip */
  trigger: PropTypes.oneOf(['mouseenter', 'click']),
  /** Whether a simple tooltip should be shown */
  simple: PropTypes.bool,
  /** Whether a ordered tooltip should be shown */
  ordered: PropTypes.bool,
  /** When you want to show a (hidden) div in the tooltip, add its identifier as selector */
  selector: PropTypes.string,
  /** Additional tippy options */
  tippyOptions: PropTypes.objectOf(PropTypes.object),
  /** The button to trigger the actionsheet */
  button: PropTypes.shape(Button.propTypes),
  /** The actions inside the actionsheet */
  actions: PropTypes.arrayOf(PropTypes.shape(Button.propTypes)),
  /** Whether a collection tooltip should be shown */
  collection: PropTypes.bool,
  /** The title above the collection (only works when collection is true) */
  title: PropTypes.string,
};

Tooltip.defaultProps = {
  actions: null,
  button: null,
  children: null,
  collection: false,
  content: null,
  ordered: false,
  position: 'top',
  selector: null,
  simple: false,
  tippyOptions: {},
  title: '',
  trigger: 'click',
};

// Needed for Storybook
Tooltip.displayName = 'Tooltip';

export default Tooltip;
