import { Controller } from '@hotwired/stimulus';

/**
 * ExplorerController class handles the interaction between the search input and the card element.
 * It shows or hides the card based on the search input value and user interactions.
 *
 * @class
 * @extends Controller
 */
export default class extends Controller {
  /**
   * Targets used in the explorer controller.
   *
   * @type {Array<string>}
   * @property {string} search - The search input element.
   * @property {string} card - The card element.
   */
  static targets = ['search', 'card'];

  /**
   * Connects the controller to the DOM elements and sets up event listeners.
   *
   * - Adds an 'input' event listener to the search target to show or hide the card based on the search input value.
   * - Adds a 'focus' event listener to the search target to show the card if the search input value is not empty.
   * - Adds a 'click' event listener to the search clear button to clear the search input value.
   * - Adds a 'click' event listener to the document to hide the card if the click is outside the card and search elements.
   *
   * @returns {void}
   */
  connect() {
    const search = this.searchTarget;
    const searchClear = document.querySelector(
      'rmv-button[event-id="search-clear-button"'
    );
    const card = this.cardTarget;

    if (search) {
      search.addEventListener('input', () => {
        if (search.value.trim() !== '') {
          card.removeAttribute('hidden');
        } else {
          card.setAttribute('hidden', 'true');
        }
      });

      search.addEventListener('focus', () => {
        if (search.value.trim() !== '') {
          card.removeAttribute('hidden');
        }
      });

      searchClear.addEventListener('click', () => {
        search.value = '';
      });
    }

    if (card) {
      document.addEventListener('click', (event) => {
        if (!card.contains(event.target) && !search.contains(event.target)) {
          card.setAttribute('hidden', 'true');
        }
      });
    }
  }
}
