import React from 'react';

// The content wrapper for data inside the dropdown
const Menu = (props) => {
  return (
    <div
      style={{
        background:
          'linear-gradient(180deg, rgba(var(--color-stable-300),1) 85px, rgba(var(--color-light),1) 85px)',
        border: '1px solid rgba(var(--color-stable-500),1)',
        zIndex: 2,
        position: 'relative',
      }}
      {...props}
    />
  );
};

export default Menu;
