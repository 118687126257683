import React from 'react';
import ActionTree from '.';

export default {
  title: 'BRMS/layout/ActionTree',
  component: ActionTree,
};

const Template = (args) => <ActionTree {...args} />;

export const Limited = () => (
  <ActionTree
    text={'Limited to 5 children'}
    active
    action={'#'}
    maxChildren={5}
    childs={[
      { text: 'Child 1' },
      { text: 'Child 2' },
      { text: 'Child 3' },
      { text: 'Child 4' },
      { text: 'Child 5' },
      { text: 'Child 6' },
      { text: 'Child 7' },
      { text: 'Child 8' },
      { text: 'Child 9' },
      { text: 'Child 10' },
    ]}
  />
);

export const Default = Template.bind({});
Default.args = {
  text: 'Parent',
  active: true,
  action: '#',
  childs: [
    {
      text: 'Child - 1',
      action: '#',
    },
    {
      text: 'Child - 2',
      action: '#',
      childs: [
        {
          text: 'Child - 2.1',
          action: '#',
          childs: [
            {
              text: 'Child - 2.1.1',
              action: '#',
              childs: [
                {
                  text: 'Child - 2.1.1.1',
                  action: '#',
                },
                {
                  text: 'Child - 2.1.1.2',
                  action: '#',
                },
                {
                  text: 'Child - 2.1.1.3',
                  action: '#',
                },
              ],
            },
            {
              text: 'Child - 2.1.2',
              action: '#',
            },
          ],
        },
      ],
    },
    {
      text: 'Child - 3',
      action: '#',
    },
    {
      text: 'Child - 4',
      action: '#',
      active: true,
      childs: [
        {
          text: 'Child - 4.1',
          action: '#',
        },
        {
          text: 'Child - 4.2',
          action: '#',
        },
      ],
    },
    {
      text: 'Child - 5',
      action: '#',
    },
  ],
};
