import React from 'react';
import Image from '.';
import Divider from '../divider';

export default {
  title: 'BRMS/ui/Image',
  component: Image,
};

const Template = (args) => <Image {...args} />;

export const Default = Template.bind({});
Default.args = {
  src: 'https://picsum.photos/1024/400',
};

export const Variants = () => (
  <>
    <Image src={'https://picsum.photos/1024/400'} />
    <Divider height={30} color={'transparent'} />
    <Image src={'https://picsum.photos/1024/400'} objectFit height={200} />
  </>
);
