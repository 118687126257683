import React, { useState } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { Divider } from '@components/ui';
import { Button } from '@components/button';
import { Status } from '@components/text';
import { Wrapper } from '@components/card';
import { Element } from '@components/form';

import styles from './informationtree.module.scss';

const cx = classNames.bind(styles);

const InformationBranch = ({
  active,
  action,
  childs,
  text,
  size,
  view,
  button,
  type,
  name,
  value,
}) => {
  const renderTree = (node) => (
    <InformationBranch type={type} {...node} key={uuidv4()} />
  );
  const [collapsed, setCollapsed] = useState(active);
  const handleClick = () => {
    setCollapsed(!collapsed);
  };

  const classes = cx({
    informationtree: true,
    view,
    childs: childs.length > 0,
  });

  const lineClass = cx({
    [`line-${size}`]: true,
  });

  return (
    <ul className={classes}>
      <li>
        <Wrapper size={size} className={styles.cardBranch}>
          <div className={styles.branch}>
            {type && (
              <>
                <Element
                  className={styles.checkbox}
                  type={type}
                  label={false}
                  options={[
                    {
                      checked: false,
                      name: name ? name : 'name',
                      value: value ? value : 'value',
                    },
                  ]}
                />
                <Divider
                  className={lineClass}
                  color={'stable-500'}
                  width={1}
                  height={54}
                />
                <Divider
                  className={lineClass}
                  color={'transparent'}
                  width={15}
                />
              </>
            )}
            {text && (
              <Status
                className={styles.status}
                title={{ text: text, color: 'dark' }}
                icon={{ icon: view ? 'warning' : 'checkmark' }}
                color={view ? 'royal' : 'positive'}
              />
            )}
            {childs && childs.length > 0 && (
              <Button
                link
                className={styles.icon}
                color={'transparent'}
                textColor={collapsed ? 'positive' : 'balanced-500'}
                icon={{
                  icon: 'chevron-down',
                  width: 10,
                  height: 10,
                  color: 'dark',
                }}
                style={{
                  transform: collapsed && 'rotate(180deg)',
                }}
                action={() => handleClick()}
              />
            )}
            {view && (
              <Button
                action={action}
                className={styles.button}
                text={button ? button : 'View'}
                link
                emphasis
              />
            )}
          </div>
        </Wrapper>
        {collapsed && childs && childs.length > 0 ? (
          <ul className={styles.list}>
            <li>{childs.map((b) => renderTree(b))}</li>
          </ul>
        ) : null}
      </li>
    </ul>
  );
};

InformationBranch.propTypes = {
  /** The action of the button */
  action: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  /** Whether the tree is active */
  active: PropTypes.bool,
  /** The text of the button */
  text: PropTypes.string.isRequired,
  /** The children of the tree */
  childs: PropTypes.arrayOf(PropTypes.shape({})),
  /** The view button (conflict/changes) */
  view: PropTypes.bool,
  /** Input type */
  type: PropTypes.string,
  /** Button text */
  button: PropTypes.string,
  /** Input name */
  name: PropTypes.string,
  /** Input value */
  value: PropTypes.string,
};

InformationBranch.defaultProps = {
  action: '',
  active: false,
  childs: [],
  size: 'm',
  view: false,
  type: '',
  button: '',
  name: '',
  value: '',
  text: '',
};

export default InformationBranch;
