import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['source', 'reflection'];
  static distortions = {
    identifier: (value) => {
      return value
        .toLowerCase()
        .replace(/(\s|-)+/g, '_')
        .replace(/[^a-z0-9_]/g, '')
        .replace(/_+/g, '_')
        .replace(/^_|_$/g, '');
    },
  };

  // note1: make sure that each reflectionTarget and sourceTarget have a `data-mirror-id="..."` with the same id to indicate that they should be mirrored
  // note2: there can be multiple reflections for one source, not the other way around
  // note3: each target should have a `data-mirror-attribute="..."` with the name of the attribute that should be mirrored
  //        (for the source this attribute is where the value comes from, for the reflection this attribute is where the value goes to)

  // this controller can currently only mirror any given attribute.
  // however, properties like 'textContent' or 'children' not, but it shouldn't be too hard to add

  /**
   * return the value of the specified attribute of the source element (or null if anything is not found (element, attribute, or value))
   * @param id {int}
   * @returns {string | null}
   */
  getSourceValue(id) {
    let returnValue = null;

    this.sourceTargets.forEach((source) => {
      if (id !== source.getAttribute('data-mirror-id')) return;

      const attribName = source.getAttribute('data-mirror-attribute');
      returnValue = source.getAttribute(attribName);
    });
    return returnValue;
  }

  /**
   * reflects the value of the source element to the reflection element.
   * It does this for the element with the given id, or for all elements if no id is given
   * @param e {CustomEvent}
   */
  reflect(e) {
    this.reflectionTargets.forEach((reflection) => {
      const reflectionId = reflection.getAttribute('data-mirror-id');

      const shouldUpdate =
        e.detail.id === undefined || e.detail.id === reflectionId;
      if (!shouldUpdate) return;

      let sourceValue = this.getSourceValue(reflectionId);
      if (sourceValue === null) return;

      const transformation =
        this.constructor.distortions[
          reflection.getAttribute('data-mirror-distort-to')
        ];
      if (transformation) {
        sourceValue = transformation(sourceValue);
      }

      const reflectionAttribName = reflection.getAttribute(
        'data-mirror-attribute'
      );

      reflection.setAttribute(reflectionAttribName, sourceValue);
    });
  }
}
