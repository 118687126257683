import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import LazyLoad from 'react-lazyload';
import { Colors } from '@components/variables';
import styles from './image.module.scss';

const cx = classNames.bind(styles);

const Image = (props) => {
  const classes = cx(
    {
      image: true,
      objectFit: props.objectFit,
    },
    props.color,
    props.className
  );

  const imageClasses = cx({
    img: true,
    round: props.round,
  });

  return (
    <div
      className={classes}
      style={{ height: props.height, width: props.width }}
    >
      <LazyLoad offset={100} height={props.height}>
        <img src={props.src} className={imageClasses} />
      </LazyLoad>
    </div>
  );
};

Image.propTypes = {
  /** The source of the image */
  src: PropTypes.string.isRequired,
  /** The background-color of the wrapping div */
  color: PropTypes.oneOf(Colors),
  /** Whether the image should be positioned absolute inside its container (cover effect) */
  objectFit: PropTypes.bool,
  /** The height of the wrapper */
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** The width of the wrapper */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Whether the image should be a circle */
  round: PropTypes.bool,
};

Image.defaultProps = {
  color: 'transparent',
};

// Needed for Storybook
Image.displayName = 'Image';

export default Image;
