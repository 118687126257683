import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { dirty: Boolean };
  static targets = ['modal', 'include', 'exclude'];

  initialize() {
    this.dirty = false;
    this.forms = this.element.querySelectorAll('form');
    this.setupFormListeners();
    this.setupTurboListener();
  }

  setupFormListeners() {
    this.forms.forEach((form) => {
      form.addEventListener('change', this.markDirty.bind(this));
      form.addEventListener('input', this.markDirty.bind(this));
    });
  }

  setupTurboListener() {
    document.addEventListener(
      'turbo:before-visit',
      this.handleTurboBeforeVisit.bind(this)
    );
  }

  markDirty(event) {
    const { target, currentTarget } = event;

    // If the target is explicitly included, mark as dirty
    if (
      this.includeTargets.includes(target) ||
      this.includeTargets.includes(currentTarget)
    ) {
      this.dirty = true;
      return;
    }

    const defaultExcludes = ['.rm-input-search', 'rmv-search'];
    // Check if the target is excluded
    if (
      this.excludeTargets.includes(target) ||
      this.excludeTargets.includes(currentTarget) ||
      defaultExcludes.some((exclude) => target.matches(exclude)) ||
      defaultExcludes.some((exclude) => currentTarget.matches(exclude))
    ) {
      return;
    }

    // If neither the target nor the current target is included or excluded, mark as dirty
    this.dirty = true;
  }

  handleTurboBeforeVisit(event) {
    if (this.dirty) {
      event.preventDefault();
      this.modalTarget.dataset.action = event.detail.url;
      this.modalTarget.open = true;
    }
  }

  continue() {
    this.modalTarget.open = false;
    window.location.href = this.modalTarget.dataset.action;
  }

  cancel() {
    this.modalTarget.open = false;
  }
}
