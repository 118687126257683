import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Colors, Spacings } from '@components/variables';
import { Detail } from '@components/text';
import Wrapper from '../wrapper';
import styles from './connection.module.scss';

const cx = classNames.bind(styles);

const ConnectionCard = ({ size, color, header, className, label, badges }) => {
  const classes = cx(
    {
      connection: true,
    },
    className
  );

  return (
    <Wrapper
      size={size}
      color={color}
      header={{
        ...header,
        title: {
          size: 'h3',
          ...header.title,
        },
      }}
      className={classes}
    >
      {badges && (
        <Detail badges={badges} label={label} className={styles.details} />
      )}
    </Wrapper>
  );
};

ConnectionCard.propTypes = {
  /** The color of the card */
  color: PropTypes.oneOf(Colors),
  /** The size of the card */
  size: PropTypes.oneOf(['no-p', ...Spacings]),
  /** The header of the card */
  header: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
  /** Adds a custom class to the card */
  className: PropTypes.string,
  /** The condition label of the card */
  label: PropTypes.object,
  /** The condition of the card */
  badges: PropTypes.arrayOf(PropTypes.shape(Detail.propTypes)),
};

ConnectionCard.defaultProps = {
  id: '',
  color: 'light',
  size: 'l',
  header: false,
  className: '',
  label: { text: '' },
  badges: [],
};

// Needed for Storybook
ConnectionCard.displayName = 'RuleCard';

export default ConnectionCard;
