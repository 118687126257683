import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Colors } from '@components/variables';
import Icon from '@components/ui/icon';
import styles from './status.module.scss';
import Title from '../title';

const cx = classNames.bind(styles);

const Status = ({ color, className, icon, title }) => {
  const classes = cx('status', color, className);
  const iconClasses = cx('icon', color);

  return (
    <div className={classes}>
      <div className={iconClasses}>
        <Icon height={9} color={'light'} {...icon} />
      </div>
      {title && (
        <Title
          className={styles.title}
          size={'body'}
          color={color}
          {...title}
        />
      )}
    </div>
  );
};

Status.propTypes = {
  /** Status icon */
  icon: PropTypes.shape(Icon.propTypes),
  /** Text behind the icon */
  title: PropTypes.shape(Title.propTypes),
  /** The color of the icon & title (unless title has its own color prop) */
  color: PropTypes.oneOf(Colors),
  /** Adds custom styles to the component. Mostly passed when used in another component */
  className: PropTypes.string,
};

Status.defaultProps = {
  icon: {},
  title: {},
  color: 'dark',
  className: '',
};

// Needed for Storybook
Status.displayName = 'Status';

export default Status;
