import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import styles from './textarea.module.scss';

const cx = classNames.bind(styles);

const Textarea = (props) => {
  const classes = cx(
    {
      textarea: true,
    },
    props.className
  );

  return (
    <textarea
      id={props.id}
      className={classes}
      type={props.type}
      name={props.name}
      disabled={props.disabled}
      readOnly={props.readonly}
      placeholder={props.placeholder}
      defaultValue={props.value}
      rows={props.rows}
      cols={props.cols}
    />
  );
};

Textarea.propTypes = {
  /** The name of the textarea */
  name: PropTypes.string.isRequired,
  /** The value of the textarea */
  value: PropTypes.string,
  /** The placeholder text */
  placeholder: PropTypes.string,
  /** Whether the textarea is disabled */
  disabled: PropTypes.bool,
  /** Whether the textarea is readonly */
  readonly: PropTypes.bool,
  /** A unique id for the textarea */
  id: PropTypes.string.isRequired,
  /** Rows of the textarea */
  rows: PropTypes.number,
  /** Columns of the textarea */
  cols: PropTypes.number
};

Textarea.defaultProps = {
  name: 'textarea',
  rows: 5
};

// Needed for Storybook
Textarea.displayName = 'Textarea';

export default Textarea;
