import React from 'react';
import { Divider } from '@components/ui';
import { Title } from '@components/text';
import AsynchronousSelect from '.';

const options = require('./data.json');

export default {
  title: 'BRMS/form/AsynchronousSelect',
  component: AsynchronousSelect,
  parameters: {
    componentSubtitle:
      'Uses React-Select (https://github.com/JedWatson/react-select) under the hood',
  },
};

const Template = (args) => (
  <>
    <AsynchronousSelect {...args} />
    <Divider height={100} color={'transparent'} />
  </>
);

export const Default = Template.bind({});

Default.args = {
  id: 'select',
  options,
};

export const Variants = (args) => (
  <>
    <Title text={'Disabled'} size={'label'} />
    <AsynchronousSelect {...Default.args} disabled />
    <Divider height={20} color={'transparent'} />
    <Title text={'Multiple'} size={'label'} />
    <AsynchronousSelect {...Default.args} multi />
    <Divider height={100} color={'transparent'} />
  </>
);
