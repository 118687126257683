import { Controller } from '@hotwired/stimulus';
import turboFetch from 'lib/turbo_fetch';

export default class extends Controller {
  static targets = ['upload'];
  static values = { url: String, answerSpreadsheetId: String };

  connect() {}

  upload(e) {
    let formData = new FormData();
    formData.append('file', e.target.files[0]);
    if (this.answerSpreadsheetIdValue != '') {
      formData.append('answer_spreadsheet_id', this.answerSpreadsheetIdValue);
    }
    turboFetch(`${this.urlValue}`, 'POST', formData);
  }

  remove(e) {
    e.preventDefault(); // prevent downloading the file
    const id = document.querySelector('[data-id]').dataset.id;
    turboFetch(`${this.urlValue}/${id}`, 'DELETE');
  }
}
