import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg width={106} height={46} fill='none' {...props}>
      <g clipPath='url(#prefix__clip0)'>
        <circle cx={23} cy={23} r={20.5} fill='#2791FF' />
        <rect
          opacity={0.7}
          x={31.193}
          y={16.932}
          width={8.913}
          height={6.239}
          rx={1.783}
          transform='rotate(45 31.193 16.932)'
          fill='#fff'
        />
        <rect
          x={12.916}
          y={18.823}
          width={8.913}
          height={6.239}
          rx={1.783}
          transform='rotate(45 12.916 18.823)'
          fill='#fff'
        />
        <rect
          opacity={0.7}
          x={24.891}
          y={23.235}
          width={8.913}
          height={11.587}
          rx={1.783}
          transform='rotate(45 24.89 23.235)'
          fill='#fff'
        />
        <rect
          x={23}
          y={8.739}
          width={8.913}
          height={11.587}
          rx={1.783}
          transform='rotate(45 23 8.739)'
          fill='#fff'
        />
        <path
          d='M52.797 30h5.82c2.686 0 4.346-1.467 4.346-3.501 0-1.745-1.286-2.886-2.873-2.946v-.121c1.43-.284 2.39-1.34 2.39-2.705 0-1.883-1.497-3.09-4.322-3.09h-5.361V30zm3.356-2.68v-2.608h1.618c1.02 0 1.642.483 1.642 1.352 0 .827-.561 1.256-1.69 1.256h-1.57zm0-4.685v-2.367h1.425c.833 0 1.449.423 1.449 1.184 0 .76-.616 1.183-1.497 1.183h-1.377zm8 7.365h3.356v-4.081h1.159L70.841 30h3.647l-2.512-4.612c1.316-.634 2.101-1.835 2.101-3.55 0-2.632-1.841-4.202-4.588-4.202h-5.337V30zm3.356-6.689v-2.994h1.183c1.177 0 1.884.459 1.884 1.521 0 1.056-.707 1.473-1.884 1.473H67.51zm7.999-5.675V30h3.284v-7.196h.096l2.753 7.1h1.98l2.753-7.052h.097V30h3.284V17.636h-4.178l-2.873 7.003h-.145l-2.874-7.003h-4.177zM98.01 21.5h3.212c-.012-2.427-1.883-4.033-4.878-4.033-2.94 0-5.016 1.582-4.998 3.936-.006 1.932 1.334 3.007 3.525 3.478l1.232.265c1.376.302 1.847.646 1.86 1.208-.013.61-.568 1.038-1.643 1.038-1.286 0-2.046-.604-2.1-1.739h-3.188c.012 3.103 2.113 4.492 5.336 4.492 3.134 0 5.011-1.352 5.023-3.791-.012-1.884-1.135-3.206-3.864-3.791l-1.014-.218c-1.17-.247-1.696-.591-1.666-1.183.006-.543.459-.942 1.497-.942 1.087 0 1.6.477 1.666 1.28z'
          fill='#30353D'
        />
      </g>
      <defs>
        <clipPath id='prefix__clip0'>
          <path fill='#fff' d='M0 0h106v46H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default SvgComponent;
