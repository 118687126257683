import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Pagination from '@components/layout/pagination';
import Divider from '@components/ui/divider';
import styles from './table.module.scss';
import Element from '@components/form/element';
import Wrapper from '@components/card/wrapper';

import { BodyRow, HeadRow, FlatRow } from '../_row';

const cx = classNames.bind(styles);

const Table = ({
  body,
  className,
  color,
  head,
  params,
  sizes,
  pagination,
  foot,
  simple,
  tableContent,
  search,
}) => {
  const [searchValue, setSearchValue] = useState('');

  const classes = cx(color, className, {
    table: !simple,
    simple,
    tableContent,
  });

  const _renderHead = (data) => (
    <tr>
      {data.map((column, i) => (
        <HeadRow
          key={i}
          column={column}
          params={params}
          simple={simple}
          size={i < sizes.length ? sizes[i] : 5}
        />
      ))}
    </tr>
  );

  const _renderFoot = (data) => (
    <tr>
      {data.map((column, i) => (
        <BodyRow
          key={i}
          index={i}
          column={column}
          columnspan={column.columnspan}
          size={i < sizes.length ? sizes[i] : 5}
        />
      ))}
    </tr>
  );

  const _renderRow = (row, index) => (
    <tr key={index} className={styles.row}>
      {row.data.map((column, i) =>
        simple ? (
          <FlatRow
            column={column}
            simple={simple}
            key={i}
            color={'assertive'}
          />
        ) : (
          <BodyRow
            className={styles.column}
            tooltipText={row.tooltipText}
            unreachable={row.unreachable}
            key={i}
            index={index}
            column={column}
            size={i < sizes.length ? sizes[i] : 5}
          />
        )
      )}
    </tr>
  );

  const handleSearch = (e) => {
    if (e.target.value === '') {
      setSearchValue('');
    } else {
      setSearchValue(e.target.value);
    }
  };

  // Filters the array using the search value.
  // If a row 'text value' matches the 'search value' then show the specific row(s).
  let arrayType = body;
  if (search && searchValue) {
    arrayType = body?.filter((item) =>
      item.data?.some((item) =>
        item.text?.toLowerCase().includes(searchValue.toLowerCase())
      )
    );
  }

  return (
    <div className={styles.wrapper}>
      {search && (
        <Wrapper size={'xs'} className={styles.searchWrapper}>
          <div className={styles.search}>
            <Element
              type={'search'}
              value={searchValue}
              onChange={handleSearch}
              {...search}
            />
          </div>
        </Wrapper>
      )}
      <table className={classes} cellSpacing='0' cellPadding='0'>
        {head && <thead>{_renderHead(head)}</thead>}
        {foot && <tfoot>{_renderFoot(foot)}</tfoot>}
        {body && (
          <tbody>
            {arrayType?.map((row, index) => _renderRow(row, index))}
          </tbody>
        )}
      </table>
      {pagination && pagination.max > 1 && (
        <div className={styles.footer}>
          <Divider height={1} width={'auto'} />
          <Pagination className={styles.pagination} {...pagination} />
        </div>
      )}
    </div>
  );
};

Table.propTypes = {
  /** Body content of the table */
  body: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  /** Overhauling classname */
  className: PropTypes.string,
  /** Default color styling */
  color: PropTypes.string,
  /** Headers of the table */
  head: PropTypes.arrayOf(PropTypes.shape({})),
  /** Inverse the flow of rows */
  inverse: PropTypes.bool,
  /** Extra parameters */
  params: PropTypes.shape({}),
  /** Sizes of the columns */
  sizes: PropTypes.arrayOf(PropTypes.number),
  /** Add pagination to a table */
  pagination: PropTypes.shape(Pagination.propTypes),
  /** Tablecontent specific styling */
  tableContent: PropTypes.bool,
  /** Add a footer */
  foot: PropTypes.array,
  /** Simple specific styling */
  simple: PropTypes.bool,
  /** Search in the table */
  search: PropTypes.oneOfType([
    PropTypes.shape(Element.propTypes),
    PropTypes.bool,
  ]),
};

Table.defaultProps = {
  className: '',
  color: '',
  simple: false,
  head: [],
  inverse: false,
  params: {},
  pagination: {},
  tableContent: false,
  sizes: [],
  foot: null,
  search: null,
};

// Needed for Storybook
Table.displayName = 'Table';

export default Table;
