import React from 'react';
import Divider from '@components/ui/divider';
import TooltipCard from '.';

export default {
  title: 'BRMS/card/TooltipCard',
  component: TooltipCard,
};

const Template = (args) => (
  <>
    <TooltipCard {...args} />
  </>
);

export const Default = Template.bind({});

Default.args = {
  title: 'test',
  children: <Divider />,
};
