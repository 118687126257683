import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['tableContainer'];

  show() {
    this.tableContainerTarget.style.display = 'block';
  }

  hideCard(event) {
    const card = event.target.closest('rmv-card');
    if (card) {
      card.style.display = 'none';
    }
  }
}
