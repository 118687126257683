import React from 'react';
import Status from '.';

export default {
  title: 'BRMS/text/Status',
  component: Status,
};

const Template = (args) => <Status {...args} />;

export const Default = Template.bind({});
Default.args = {
  icon: {
    icon: 'checkmark',
  },
  title: {
    text: 'Published',
  },
  color: 'positive',
};

export const Icon = () => (
  <>
    <Status color={'positive'} icon={{ icon: 'checkmark' }} />
  </>
);

export const IconAndText = () => (
  <>
    <Status
      color={'royal'}
      icon={{ icon: 'warning' }}
      title={{ text: 'Warning' }}
    />
  </>
);

export const IconColorAndText = () => (
  <>
    <Status
      color={'positive'}
      icon={{ icon: 'checkmark' }}
      title={{ text: 'Published', color: 'dark' }}
    />
  </>
);
