import React from 'react';
import { Title } from '@components/text';
import RadioGroup from '.';

export default {
  title: 'BRMS/form/RadioGroup',
  component: RadioGroup,
};

const Template = (args) => (
  <>
    <RadioGroup {...args} />
  </>
);

export const Default = Template.bind({});

Default.args = {
  header: {
    title: {
      text: 'Symptoms',
    },
    content: {
      text: '4 conflicts',
    },
    headerBackground: false,
  },
  subjects: [
    {
      subject: {
        label: 'Activation condition',
        options: [
          {
            checked: false,
            name: 'name',
            value: 'value',
            fields: [
              {
                label: 'if what_illness == broken_bones',
                status: 'Current',
              },
            ],
          },
          {
            checked: false,
            fields: [
              {
                label: 'if what_illness == broken_bones',
                status: 'Update',
              },
              {
                label: '&& what_illness == Bruised_bones',
              },
            ],
            name: 'name',
            value: 'value',
          },
        ],
      },
    },
    {
      subject: {
        label: 'Question help text',
        options: [
          {
            checked: false,
            name: 'name',
            value: 'value',
            fields: [
              {
                label: 'Please select your illness',
                status: 'Current',
              },
            ],
          },
          {
            checked: false,
            fields: [
              {
                label: 'Select your illness',
                status: 'Update',
              },
            ],
            name: 'name',
            value: 'value',
          },
        ],
      },
    },
    {
      subject: {
        label: 'Answers',
        options: [
          {
            checked: false,
            name: 'name',
            value: 'value',
            fields: [
              {
                label: 'ill_01',
                status: 'Current',
              },
              {
                label: 'ill_02',
              },
              {
                label: 'ill_03',
              },
            ],
          },
          {
            checked: false,
            fields: [
              {
                label: 'ill_01',
                status: 'Update',
              },
              {
                label: 'ill_02',
              },
              {
                label: 'ill_03',
              },
            ],
            name: 'name',
            value: 'value',
          },
        ],
      },
    },
    {
      subject: {
        label: 'Assignments',
        options: [
          {
            checked: false,
            name: 'name',
            value: 'value',
            assignment: true,
            fields: [
              {
                label: 'if answer is ill_01 then penalty +1',
                status: 'Current',
              },
              {
                label: 'If answer is ill_02 then penalty +2',
              },
            ],
          },
          {
            checked: false,
            assignment: true,
            fields: [
              {
                label: 'If answer is ill_01 then penalty +1',
                status: 'Update',
              },
            ],
            name: 'name',
            value: 'value',
          },
        ],
      },
    },
  ],
  buttons: [
    {
      text: 'Cancel',
      textColor: 'positive',
      color: 'stable-500',
      outline: true,
      emphasis: true,
    },
    {
      text: 'Continue',
      emphasis: true,
    },
  ],
};
