import { Controller } from '@hotwired/stimulus';
import { useDebounce } from 'stimulus-use';
import turboFetch from 'lib/turbo_fetch';

export default class extends Controller {
  static targets = ['search', 'blob'];
  static values = { url: String };
  static debounces = ['search'];

  connect() {
    this.controller = null;
    useDebounce(this, { wait: 300 });
    this.search();
  }

  search() {
    if (this.controller) {
      this.controller.abort();
    }

    this.controller = new AbortController();
    const signal = this.controller.signal;

    turboFetch(
      `${this.urlValue}?spreadsheet_id=${this.blobTarget.value}&query=${this.searchTarget.value}`,
      'POST',
      '',
      signal
    );
  }
}
