import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Button } from '@components/button';
import { Colors, Spacings } from '@components/variables';
import styles from './pagination.module.scss';

const cx = classNames.bind(styles);

const Pagination = (props) => {
  const classes = cx('pagination', props.className);

  const next = props.current === props.max ? props.current : props.current + 1;
  const previous = props.current === 1 ? props.current : props.current - 1;

  const range = (start, end) => {
    return Array(end - start + 1)
      .fill()
      .map((_, idx) => start + idx)
      .filter((v) => v > 0 && v <= props.max);
  };

  const pages = range(props.current - 3, props.current + 3);

  const formatUrl = (page) => {
    try {
      const url = new URL(window.location.href);
      url.searchParams.set('page', page);
      return url.href;
    } catch (e) {}
  };

  return pages.length == 1 ? null : (
    <ul className={classes}>
      {props.current !== 1 && (
        <Button
          action={formatUrl(previous)}
          className={styles.previous}
          icon={{ icon: 'chevron-left-round' }}
          link
          text={false}
          color={'balanced-500'}
          size={'s'}
          {...props.previous}
        />
      )}
      {pages.map((page, index) => {
        return (
          <li key={index}>
            <Button
              className={styles.button}
              text={page.toString()}
              action={formatUrl(page)}
              color={page == props.current ? 'positive' : 'balanced-100'}
              textColor={page == props.current ? 'positive' : 'balanced-500'}
              outline
              size={'s'}
            />
          </li>
        );
      })}
      {props.current !== props.max && (
        <Button
          action={formatUrl(next)}
          className={styles.next}
          icon={{ icon: 'chevron-right-round' }}
          link
          text={false}
          color={'balanced-500'}
          size={'s'}
          {...props.next}
        />
      )}
    </ul>
  );
};

Pagination.propTypes = {
  /** The color of the card */
  current: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** The maximum number of pages */
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** The param used for pagination */
  param: PropTypes.string,
  /** Additional styles, normally passed via another component */
  className: PropTypes.string,
};

Pagination.defaultProps = {};

// Needed for Storybook
Pagination.displayName = 'Pagination';

export default Pagination;
