import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Button from '@components/button/button';
import Wrapper from '@components/card/wrapper';
import Divider from '../../divider';
import styles from '../action-sheet.module.scss';

const cx = classNames.bind(styles);

const Base = ({ className, actions, children }) => {
  const classes = cx(
    {
      actionSheet: true,
    },
    className
  );
  return (
    <Wrapper size={'no-p'} color={'light'} className={classes}>
      {actions.length > 0
        ? actions.map((action, index) => {
            const btnClass = cx({
              button: true,
              centered: action.centered || !action.icon,
            });
            return (
              <div key={index}>
                {index > 0 && <Divider height={1} />}
                <Button
                  className={btnClass}
                  color={'light'}
                  size={'l'}
                  {...action}
                />
              </div>
            );
          })
        : children}
    </Wrapper>
  );
};

Base.propTypes = {
  /** The actions in the sheet (array of buttons) */
  actions: PropTypes.arrayOf(
    PropTypes.shape({ ...Button.propTypes, centered: PropTypes.bool })
  ),
  /** Additional styles, mostly passed via another component */
  className: PropTypes.string,
  /** Content inside the actionsheet */
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
};

Base.defaultProps = {
  actions: {},
  className: '',
  children: false,
};

// Needed for Storybook
Base.displayName = 'Base';

export default Base;
