import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Colors, Spacings } from '@components/variables';
import Detail from '@components/text/detail';
import Content from '@components/text/content';
import Divider from '@components/ui/divider';
import Badge from '@components/badge/badge';
import styles from './timeline.module.scss';

const cx = classNames.bind(styles);

const Timeline = (props) => {
  const classes = cx(
    {
      timeline: true,
    },
    props.className
  );

  return (
    <div>
      <ul className={classes}>
        {props.steps &&
          props.steps.map((step, index) => {
            if (step.detail) {
              step.detail.label.emphasis = true;
            }
            let color = 'stable-700';
            if (step.detail) {
              color = 'dark';
              step.detail.label.color = color;
            }
            const status = cx(
              {
                positive: true,
                detail: true,
                dots: step.status === 'unpublished',
              },
              step.status
            );
            return (
              <li className={status} key={index}>
                <span className={styles.circle} />
                {step.detail && (
                  <Detail
                    classname={styles.detailContent}
                    reverse
                    {...step.detail}
                  />
                )}
                {step.badge && (
                  <div className={styles.badge}>
                    <Badge type={'operator'} size={'m'} {...step.badge} />
                  </div>
                )}
                {step.date && (
                  <div className={styles.date}>
                    <Content size={'s'} {...step.date} color={'balanced-100'} />
                    <Divider width={5} color={'transparent'} />
                    {step.from && (
                      <Content
                        size={'s'}
                        text={`by ${step.from.text}`}
                        color={'balanced-100'}
                      />
                    )}
                  </div>
                )}
              </li>
            );
          })}
      </ul>
    </div>
  );
};

Timeline.propTypes = {
  /** The background-color of the steps */
  color: PropTypes.oneOf(Colors).isRequired,
  /** The space around the steps */
  size: PropTypes.oneOf(Spacings),
  /** The actual steps */
  steps: PropTypes.arrayOf(PropTypes.shape(Detail.propTypes)),
  /** The status option per step in steps */
  status: PropTypes.oneOf(['published', 'unpublished']),
  /** The button */
  badge: PropTypes.shape(Badge.propTypes),
  /** The date */
  date: PropTypes.shape(Content.propTypes),
  /** From who */
  from: PropTypes.shape(Content.propTypes),
  /** The detail */
  detail: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape(Detail.propTypes),
  ]),
};

Timeline.defaultProps = {
  color: 'light',
  size: 'm',
  status: 'published',
};

Timeline.displayName = 'Timeline';

export default Timeline;
