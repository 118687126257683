import React from 'react';
import DragAndDrop from '.';

export default {
  title: 'BRMS/ui/DragAndDrop',
  component: DragAndDrop,
};

const Template = () => {
  let x = 4;
  const timer = setInterval(() => {
    const div = document.createElement('div');
    const p = document.createElement('p');
    x += 1;
    p.innerText = `Div ${x}`;
    div.appendChild(p);
    document.querySelector('#placeholder').append(div);
    if (x === 10) {
      clearInterval(timer);
    }
  }, 5000);
  return (
    <>
      <p>
        One new div will be added every 5 seconds, until 10 divs are present
      </p>
      <div id='placeholder'>
        <div>
          <p>Div 1</p>
        </div>
        <div>
          <p>Div 2</p>
        </div>
        <div>
          <p>Div 3</p>
        </div>
        <div>
          <p>Div 4</p>
        </div>
      </div>
      <DragAndDrop selector={'placeholder'} />
    </>
  );
};
export const Default = Template.bind({});
