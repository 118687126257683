import React, { useState } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { Button } from '@components/button';
import { Content } from '@components/text';
import { Divider } from '@components/ui';

import styles from './actiontree.module.scss';

const cx = classNames.bind(styles);

const renderTree = (node, spacing, active, maxChildren, overrideMaximum) => (

  <ActionBranch
    spacing={spacing}
    active={active}
    key={uuidv4()}
    maxChildren={maxChildren}
    overrideMaximum={overrideMaximum}
    {...node}
  />
);
const ActionBranch = ({
  maxChildren,
  active,
  action,
  text,
  childs,
  spacing,
  overrideMaximum,
  ...props
}) => {
  const [collapsed, setCollapsed] = useState(active);
  const handleClick = () => {
    setCollapsed(!collapsed);
  };

  const classes = cx({
    actiontree: true,
  });

  return (
    <ul className={classes}>
      <Divider height={spacing} color={'transparent'} />
      <li>
        {action ? (
          <>
            <Button
              link
              action={action}
              color={'transparent'}
              textColor={collapsed ? 'positive' : 'balanced-500'}
              text={text}
              {...props}
            />
            {childs && childs.length > 0 && (
              <Button
                link
                className={styles.icon}
                color={'transparent'}
                textColor={collapsed ? 'positive' : 'balanced-500'}
                icon={{ icon: 'chevron-up' }}
                rotatable={{
                  transform: collapsed && 'rotate(180deg)',
                  transistion: 'all',
                  width: 10,
                  height: 10,
                }}
                action={() => handleClick()}
                {...props}
              />
            )}
          </>
        ) : text && Array.isArray(text) ? (
          <div className={styles.listitem}>
            {text.map((item) => (
              <Content
                color={collapsed ? 'positive' : 'balanced-500'}
                className={styles.content}
                key={uuidv4()}
                {...item}
              />
            ))}
          </div>
        ) : (
          <Content
            text={text}
            color={collapsed ? 'positive' : 'balanced-500'}
            className={styles.content}
            {...props}
          />
        )}
        {collapsed && childs && childs.length > 0 ? (
          <ul className={styles.list}>
            <li>
              {childs.map((b, i) => {
                if (i < maxChildren)
                  return renderTree(b, spacing, active, maxChildren);
                if (overrideMaximum) {
                  return renderTree(b, spacing, active, maxChildren);
                }
              })}
              {childs.length > maxChildren &&
                !overrideMaximum &&
                renderTree(
                  {
                    text: String(`${childs.length - maxChildren} ...more`),
                    color: 'balanced-500',
                    action: () => {},
                    active: false,
                    childs: [...childs].slice(maxChildren).map((c, y) => ({
                      ...c,
                      text: String(`[index]: ${y + maxChildren}`),
                      active: true,
                    })),
                  },
                  spacing,
                  active,
                  maxChildren,
                  true
                )}
            </li>
          </ul>
        ) : null}
      </li>
    </ul>
  );
};

ActionBranch.propTypes = {
  /** The action of the button */
  action: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  /** Whether the tree is active */
  active: PropTypes.bool,
  /** The text of the button */
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.shape({})),
  ]).isRequired,
  /** The children of the tree */
  childs: PropTypes.arrayOf(PropTypes.shape({})),
  /** Sets spacing between branches */
  spacing: PropTypes.number,
  /** Maximum number of children (folded) */
  maxChildren: PropTypes.number,
};

ActionBranch.defaultProps = {
  action: '',
  active: false,
  childs: [],
  spacing: 15,
  maxChildren: null,
};

export default ActionBranch;
