import { Controller } from '@hotwired/stimulus';
import { useDebounce } from 'stimulus-use';

export default class extends Controller {
  static targets = ['search', 'sortAnswer'];
  static debounces = ['submit'];

  initialize() {
    if (window.scrollYPosition > 0) {
      window.scrollTo(0, window.scrollYPosition);
      window.scrollYPosition = 0;
    }
  }

  connect() {
    useDebounce(this, { wait: 300 });
    if (this.hasSearchTarget) {
      setTimeout(() => {
        this.searchTarget.hasAttribute('autofocus') &&
          this.searchTarget.focus();
      }, 100);
    }
  }

  sortAnswerChanged(event) {
    const selectedValue = event.target.value;
    this.sortAnswerTarget.value = selectedValue;
    this.element.requestSubmit();
  }

  regenerateApiKey() {
    document.getElementById('regenerate_api_key_field').value = 'true';
    const form = document.getElementById('setting_form');
    form.submit();
  }

  submit() {
    // This is a workaround to prevent the page from scrolling to the top when submitting a form
    if (this.element.hasAttribute('data-turbo-preserve-scroll')) {
      window.scrollYPosition = window.scrollY;
    }
    this.element.requestSubmit();
  }

  clear() {
    Turbo.visit(this.element.action);
  }
}
