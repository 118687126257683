// DEV: Delete this dynamic modal controller if all modals are converted to using 'modal_controller.js' only
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  hideModal() {
    const frame = this.element.querySelector('brms-modal');
    frame.setAttribute('hidden', '');
  }

  // hide modal when ESC is pressed
  closeWithKeyboard(e) {
    if (e.code == 'Escape') {
      this.hideModal();
    }
  }

  // hide modal when background is clicked
  closeBackground(e) {
    if (e && this.element.contains(e.target)) {
      return;
    }
    this.hideModal();
  }
  showModal(e) {
    e.preventDefault();
    const frame = this.element.querySelector('brms-modal');
    frame.removeAttribute('hidden');
  }
}
