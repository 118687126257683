import React from 'react';
import PropTypes from 'prop-types';
import Button from '@components/button/button';
import Base from './base';
import Form from './form';

const Actionsheet = (props) => {
  const hasFormElements = props.actions.filter(
    (action) => action.action === 'checkbox'
  );
  return hasFormElements.length > 0 ? <Form {...props} /> : <Base {...props} />;
};

Actionsheet.propTypes = {
  /** The actions in the sheet (array of buttons) */
  actions: PropTypes.arrayOf(
    PropTypes.shape({ ...Button.propTypes, centered: PropTypes.bool })
  ),
  /** Additional styles, mostly passed via another component */
  className: PropTypes.string,
};

Actionsheet.defaultProps = {
  actions: {},
  className: '',
};

// Needed for Storybook
Actionsheet.displayName = 'Actionsheet';

export default Actionsheet;
