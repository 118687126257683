import React from 'react';
import { Title } from '@components/text';
import HtmlCard from '.';

export default {
  title: 'BRMS/card/HtmlCard',
  component: HtmlCard,
  parameters: {
    componentSubtitle:
      'This card is used to show a hidden div as child. Mostly used for rendering forms',
  },
};

const Template = (args) => (
  <>
    <div id='test'>
      <Title text={'I was hidden'} color={'dark'} />
    </div>
    <HtmlCard {...args} />
  </>
);

export const Default = Template.bind({});

Default.args = {
  id: 'test',
};
