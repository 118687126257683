// Hardcodes instead of grabbing them from css variables for now
// because of SSR
const Colors = [
  'transparent',
  'light',
  'stable-100',
  'stable-300',
  'stable-500',
  'stable-700',
  'stable-900',
  'positive',
  'positive-100',
  'positive-300',
  'balanced-100',
  'balanced-300',
  'balanced-500',
  'energized',
  'assertive',
  'royal',
  'dark',
];
const Spacings = ['xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl'];
const Alignments = ['left', 'center', 'right'];
const Fonts = ['--font-stack', 'alt', 'handwriting'];
const Sizes = ['xxl', 'xl', 'l', 'm', 's'];
const Radius = ['xs', 's', 'm', 'l', 'xl'];
const Shadows = ['s'];
export { Colors, Spacings, Alignments, Fonts, Sizes, Radius, Shadows };
