import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Icon from '@components/ui/icon';
import Tooltip from '@components/ui/tooltip';
import Button from '@components/button/button';
import Title from '@components/text/title';
import styles from './breadcrumbs.module.scss';

const cx = classNames.bind(styles);

const Breadcrumbs = ({ items, className }) => {
  const classes = cx(
    {
      breadcrumbs: true,
    },
    className
  );
  const renderBreadcrumbs = (crumbs) => {
    return crumbs.map((item, index) => (
      <li className={styles.item} key={index}>
        {index > 0 && (
          <Icon
            className={styles.icon}
            icon={'chevron-right'}
            color={'balanced-500'}
            width={10}
            height={10}
          />
        )}
        <Button
          key={index}
          link
          color={
            (items.length - 1 === index && 'dark') || item.color || 'positive'
          }
          textSize={'h1'}
          className={styles.action}
          {...item}
        />
      </li>
    ));
  };
  return (
    <ul className={classes}>
      {items.length > 3 && (
        <>
          <Tooltip
            content={items.splice(0, items.length - 3)}
            position={'bottom-start'}
            row
            trigger={'mouseenter'}
          >
            <Title text={'...'} color='positive' />
          </Tooltip>
          <Icon
            className={styles.icon}
            icon={'chevron-right'}
            color={'balanced-500'}
            width={10}
            height={10}
          />
        </>
      )}
      {renderBreadcrumbs(items)}
    </ul>
  );
};

Breadcrumbs.propTypes = {
  /** All items to be rendered in the list */
  items: PropTypes.arrayOf(
    PropTypes.shape({ action: PropTypes.string, ...Title.propTypes })
  ),
  /** Additional styles, mostly passed via another component */
  className: PropTypes.string,
};

Breadcrumbs.defaultProps = {
  items: [],
  className: '',
};

// Needed for Storybook
Breadcrumbs.displayName = 'Breadcrumbs';

export default Breadcrumbs;
