import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Button from '@components/button/button';
import Element from '@components/form/element';
import Base from '../base';
import Divider from '../../divider';
import styles from '../action-sheet.module.scss';

const cx = classNames.bind(styles);

const Form = ({ className, actions }) => {
  const classes = cx(
    {
      actionSheet: true,
    },
    className
  );
  return (
    <Base size={'no-p'} color={'light'} className={classes}>
      {actions.map((action, index) => {
        const btnClass = cx({
          button: true,
          centered: action.centered || !action.icon,
        });
        return action.action === 'checkbox' ? (
          <Element
            key={index}
            label={'Checkbox'}
            type={'checkbox'}
            options={[action.options]}
          />
        ) : (
          <div key={index}>
            {index > 0 && <Divider height={1} />}
            <Button
              className={btnClass}
              color={'light'}
              size={'l'}
              {...action}
            />
          </div>
        );
      })}
    </Base>
  );
};

Form.propTypes = {
  /** The actions in the sheet (array of buttons) */
  actions: PropTypes.arrayOf(
    PropTypes.shape({ ...Button.propTypes, centered: PropTypes.bool })
  ),
  /** Additional styles, mostly passed via another component */
  className: PropTypes.string,
};

Form.defaultProps = {
  actions: {},
  className: '',
};

// Needed for Storybook
Form.displayName = 'Base';

export default Form;
