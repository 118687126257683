import React from 'react';
import ActionSheet from '.';
import Divider from '../divider';

export default {
  title: 'BRMS/ui/ActionSheet',
  component: ActionSheet,
};

const Template = (args) => <ActionSheet {...args} />;

export const Default = Template.bind({});
Default.args = {
  actions: [
    {
      icon: {
        icon: 'warning-square',
        color: 'positive',
      },
      text: 'Change password',
      action: '#',
    },
    {
      icon: {
        icon: 'warning-square',
        color: 'assertive',
      },
      text: 'Logout',
      action: '#',
    },
    {
      icon: {
        icon: 'warning-square',
        color: 'energized',
      },
      text: 'Remove account',
      action: '#',
    },
  ],
};

export const Variants = () => (
  <>
    <ActionSheet
      actions={[
        {
          centered: true,
          text: 'Change password',
          action: '#',
        },
        {
          centered: true,
          text: 'Logout',
          action: '#',
        },
        {
          icon: {
            icon: 'warning-square',
            color: 'energized',
          },
          text: 'Remove account',
          action: '#',
        },
      ]}
    />
    <Divider height={20} color={'transparent'} />
    <ActionSheet
      actions={[
        {
          centered: true,
          text: 'Change password',
          action: 'checkbox',
          options: {
            checked: false,
            label: 'Other',
            name: '...',
            value: 'other',
          },
        },
        {
          centered: true,
          text: 'Logout',
          action: 'checkbox',
          options: {
            checked: false,
            label: 'Other',
            name: '...',
            value: 'other',
          },
        },
        {
          icon: {
            icon: 'warning-square',
            color: 'energized',
          },
          text: 'Remove account',
          action: '#',
        },
      ]}
    />
  </>
);
