import React from 'react';
import RadioSelect from '.';

export default {
  title: 'BRMS/form/RadioSelect',
  component: RadioSelect,
};

const Template = (args) => (
  <>
    <RadioSelect {...args} />
  </>
);

export const Default = Template.bind({});

Default.args = {
  options: [
    {
      checked: true,
      label: 'Value',
      name: 'same',
      value: 'value',
    },
    {
      checked: false,
      label: 'Range',
      name: 'same',
      value: 'range',
    },
  ],
};
