import React from 'react';
import PropTypes from 'prop-types';
import Content from '@components/text/content';
import Title from '@components/text/title';
import Status from '@components/text/status';
import Button from '@components/button/button';
import Divider from '@components/ui/divider';
import Tooltip from '@components/ui/tooltip';
import { Spacings, Colors } from '@components/variables';
import classNames from 'classnames/bind';
import Wrapper from '../wrapper';
import styles from './question.module.scss';

const cx = classNames.bind(styles);

const QuestionCard = ({
  size,
  color,
  title,
  label,
  content,
  className,
  buttons,
  diagram,
  unreachable,
  tooltipText,
}) => {
  const cardClasses = cx({ wrapper: true, diagram, unreachable }, className);
  return (
    <Wrapper size={size} color={color} className={cardClasses}>
      <div className={styles.contentWrapper}>
        {unreachable && (
          <div className={styles.unreachableLabel}>
            <Tooltip
              trigger={'mouseenter'}
              position={'top-start'}
              content={{
                text: tooltipText || 'Warning, there might be something wrong',
              }}
              simple
            >
              <Status color={'royal'} icon={{ icon: 'warning' }} />
            </Tooltip>
            {label && (
              <Content
                size={'s'}
                color={unreachable ? 'royal' : label.color}
                {...label}
              />
            )}
          </div>
        )}
        {label && !unreachable && (
          <Content
            size={'s'}
            color={unreachable ? 'royal' : label.color}
            {...label}
          />
        )}
        {label && <Divider height={5} color={'transparent'} />}
        {title && <Title size={'h4'} color={'dark'} {...title} />}
        {title && <Divider height={10} color={'transparent'} />}
        {content && (
          <div className={styles.content}>
            {content.map((c, i) => (
              <div className={styles.content} key={i}>
                {i > 0 && <Divider width={5} color={'transparent'} />}
                {i > 0 && <Content color={'positive'} size={'s'} text={'•'} />}
                {i > 0 && <Divider width={5} color={'transparent'} />}
                <Content
                  color={unreachable ? 'royal' : c.color}
                  size={'s'}
                  {...c}
                />
              </div>
            ))}
          </div>
        )}
      </div>
      {buttons && (
        <div className={styles.buttons}>
          {buttons.map((button, index) =>
            button.tooltip ? (
              <Tooltip
                key={index}
                content={{ text: 'Loading...' }}
                simple
                {...button.tooltip}
              >
                <Button size={'s'} link {...button} />
              </Tooltip>
            ) : (
              <Button key={index} size={'s'} link {...button} />
            )
          )}
        </div>
      )}
    </Wrapper>
  );
};

QuestionCard.propTypes = {
  /** The color of the card */
  color: PropTypes.oneOf(Colors),
  /** The size of the card */
  size: PropTypes.oneOf(['no-p', ...Spacings]),
  /** The label of the card, above the title */
  label: PropTypes.shape(Title.propTypes),
  /** The title of the card */
  title: PropTypes.shape(Title.propTypes),
  /** The list items of the card */
  content: PropTypes.arrayOf(PropTypes.shape(Content.propTypes)),
  /** Additional styles, mostly passed via another component */
  className: PropTypes.string,
  /** Actions of the card */
  buttons: PropTypes.arrayOf(PropTypes.shape(Button.propTypes)),
  /** Automatically added when the card is used inside the diagram view. Sets the card on a fixed width */
  diagram: PropTypes.bool,
  /** The status warning */
  unreachable: PropTypes.bool,
  /** The tooltip for the card */
  tooltipText: PropTypes.string,
};

QuestionCard.defaultProps = {
  color: 'light',
  size: 'm',
  className: '',
  content: [],
  buttons: [],
  label: '',
  title: '',
  diagram: false,
  unreachable: false,
};

// Needed for Storybook
QuestionCard.displayName = 'QuestionCard';

export default QuestionCard;
