import React from 'react';
import InformationTree from '.';

export default {
  title: 'BRMS/layout/InformationTree',
  component: InformationTree,
};

const Template = (args) => <InformationTree {...args} />;

export const Default = Template.bind({});
Default.args = {
  type: 'checkbox',
  text: 'Parent',
  active: true,
  childs: [
    {
      text: 'Child - 1',
      action: '#conflict',
      view: true,
      button: 'View conflict',
    },
    {
      text: 'Child - 2',
      action: '#',
      view: true,
      selected: true,
      childs: [
        {
          text: 'Child - 2.1',
        },
      ],
    },
    {
      text: 'Child - 3',
      action: '#',
    },
    {
      text: 'Child - 4',
      action: '#',
      active: true,
      childs: [
        {
          text: 'Child - 4.1',
          action: '#',
        },
        {
          text: 'Child - 4.2',
          action: '#',
        },
      ],
    },
    {
      text: 'Child - 5',
      action: '#',
    },
  ],
};

export const Readonly = () => (
  <InformationTree {...Default.args} type={false} />
);
