import React from 'react';
import { Divider } from '@components/ui';
import DetailsCard from '.';

export default {
  title: 'BRMS/card/DetailsCard',
  component: DetailsCard,
};

const Template = (args) => <DetailsCard {...args} />;

export const Default = Template.bind({});

Default.args = {
  details: [
    {
      label: {
        text: 'Name',
      },
      value: {
        text: 'Ben Smith',
      },
    },
    {
      label: {
        text: 'E-mail',
      },
      value: {
        text: 'ben.smith@gmail.com',
      },
    },
  ],
};

export const Variants = () => (
  <>
    <DetailsCard
      {...Default.args}
      header={{
        title: {
          text: 'Update 02-09-2020 9:45 PM',
        },
      }}
    />
    <Divider height={20} color={'transparent'} />
    <DetailsCard
      {...Default.args}
      header={{
        title: {
          text: 'Update 02-09-2020 9:45 PM',
        },
      }}
      action={'#'}
    />
    <Divider height={20} color={'transparent'} />
    <DetailsCard
      {...Default.args}
      header={{
        title: {
          text: 'Update 02-09-2020 9:45 PM',
        },
        status: {
          color: 'positive',
          icon: {
            icon: 'checkmark',
          },
          title: {
            text: 'Published',
          },
        },
      }}
      action={'#'}
    />
    <Divider height={20} color={'transparent'} />
    <DetailsCard
      header={{
        title: {
          text: 'Update 02-09-2020 9:45 PM',
        },
        content: {
          text: 'Life insurance',
        },
      }}
      details={[
        {
          label: {
            text: 'Status',
          },
          value: {
            status: {
              color: 'royal',
              icon: {
                icon: 'warning',
              },
              title: {
                text: 'Update required',
              },
            },
          },
        },
        {
          label: {
            text: 'Changes',
          },
          value: {
            text: 'Done',
          },
        },
      ]}
      action={'#'}
    />
    <Divider height={20} color={'transparent'} />
    <DetailsCard
      header={{
        title: {
          text: 'Update 02-09-2020 9:45 PM',
        },
        content: {
          text: 'Life insurance',
        },
      }}
      details={[
        {
          label: {
            text: 'Status',
          },
          value: {
            status: {
              color: 'positive',
              icon: {
                icon: 'checkmark',
              },
              title: {
                text: 'Published',
              },
            },
          },
        },
        {
          label: {
            text: 'Changes',
          },
          value: {
            text: 'Done',
          },
        },
      ]}
    />
  </>
);
