import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Button from '../button';
import styles from './group.module.scss';

const cx = classNames.bind(styles);

const Group = ({ buttons, className }) => {
  const classes = cx(
    {
      group: true,
    },
    className
  );

  const renderButtons = (items) => {
    return items.map((item, index) => (
      <li className={styles.item} key={index}>
        <Button className={styles.button} {...item} />
      </li>
    ));
  };
  return <ul className={classes}>{renderButtons(buttons)}</ul>;
};

// Needed for Storybook
Group.displayName = 'Group';

Group.propTypes = {
  buttons: PropTypes.arrayOf(PropTypes.shape(Button.propTypes)).isRequired,
  className: PropTypes.string,
};

Group.defaultProps = {
  className: '',
};

export default Group;
