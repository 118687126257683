import React from 'react';
import PropTypes from 'prop-types';
import Detail from '@components/text/detail';
import Title from '@components/text/title';
import { Spacings, Colors } from '@components/variables';
import classNames from 'classnames/bind';
import Wrapper from '../wrapper';
import styles from './details.module.scss';

const cx = classNames.bind(styles);

const DetailsCard = ({ className, size, action, header, details }) => {
  const classes = cx({ details: true }, className);
  return (
    <Wrapper
      size={size}
      action={action}
      header={Object.keys(header).length > 0 ? header : false}
    >
      <div className={classes}>
        {details &&
          details.map((detail, index) => (
            <div className={styles.detail} key={index}>
              <Detail {...detail} />
            </div>
          ))}
      </div>
    </Wrapper>
  );
};

DetailsCard.propTypes = {
  /** The color of the card */
  color: PropTypes.oneOf(Colors),
  /** Padding of the card */
  size: PropTypes.oneOf(Spacings),
  /** Action of the card */
  action: PropTypes.string,
  /** Header of the card (accepts all title props) */
  header: PropTypes.shape(Title.propTypes),
  /** Details information */
  details: PropTypes.arrayOf(PropTypes.shape(Detail.propTypes)),
  /** Additional styles, mostly passed via another component */
  className: PropTypes.string,
};

DetailsCard.defaultProps = {
  color: 'light',
  size: 'm',
  className: '',
  action: '',
  header: {},
  details: [],
};

// Needed for Storybook
DetailsCard.displayName = 'DetailsCard';

export default DetailsCard;
