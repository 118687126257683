import React from 'react';
import { Title } from '@components/text';
import ConnectionCard from '.';

export default {
  title: 'BRMS/card/ConnectionCard',
  component: ConnectionCard,
};

const Template = (args) => (
  <>
    <ConnectionCard {...args} />
  </>
);

export const Default = Template.bind({});

Default.args = {
  header: {
    title: {
      text: 'With header & buttons',
    },
    buttons: [
      {
        icon: {
          icon: 'trash',
        },
        color: 'assertive',
      },
      {
        icon: {
          icon: 'edit',
        },
        color: 'positive',
      },
    ],
  },
  label: {
    text: 'Activation condition',
  },
  badges: [
    {
      values: [
        {
          content: {
            text: 'Hospital',
          },
        },
        {
          content: {
            text: '==',
          },
          color: 'energized',
        },
        {
          content: {
            text: 'true',
          },
          color: 'energized',
        },
      ],
    },
  ],
};
