import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Colors, Spacings } from '@components/variables';
import Floating from '@components/button/floating';
import Divider from '@components/ui/divider';
import styles from './radioGroup.module.scss';
import Wrapper from '@components/card/wrapper';
import Element from '@components/form/element';

const cx = classNames.bind(styles);

const RadioGroup = ({ size, color, className, header, subjects, buttons }) => {
  const classes = cx(
    {
      radioGroup: true,
    },
    className
  );

  return (
    <>
      {subjects && (
        <Wrapper size={size} color={color} className={classes} header={header}>
          <div className={styles.subjects}>
            {subjects.map((item, index) => (
              <Element key={index} type={'radio'} column {...item.subject} />
            ))}
          </div>
        </Wrapper>
      )}
      {buttons && <Divider height={13} color={'transparent'} />}
      {buttons && <Floating filled noSticky buttons={buttons} />}
    </>
  );
};

RadioGroup.propTypes = {
  /** The color of the card */
  color: PropTypes.oneOf(Colors),
  /** The size of the card */
  size: PropTypes.oneOf(['no-p', ...Spacings]),
  /** Adds a custom class to the card */
  className: PropTypes.string,
  /** The header of the card */
  header: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.bool]),
  /** The subjects (I.E. activation condition, etc...) */
  subjects: PropTypes.arrayOf(PropTypes.shape(Element.propTypes)),
  /** The floating buttons */
  buttons: PropTypes.arrayOf(PropTypes.shape(Floating.propTypes)),
};

RadioGroup.defaultProps = {
  color: 'light',
  size: 'l',
  header: false,
  className: '',
  subjects: [],
  buttons: [],
};

// Needed for Storybook
RadioGroup.displayName = 'RadioGroup';

export default RadioGroup;
