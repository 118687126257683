import React from 'react';
import CodeEditor from '.';
import dummyData from './data.json';

export default {
  title: 'BRMS/form/CodeEditor',
  component: CodeEditor,
};

const Template = () => (
  <>
    <CodeEditor {...dummyData} showGutter url={''} />
  </>
);

export const Default = Template.bind({});
