import React from 'react';
import VariableCard from '.';

export default {
  title: 'BRMS/card/VariableCard',
  component: VariableCard,
};

const Template = (args) => <VariableCard {...args} />;

export const Default = Template.bind({});

Default.args = {
  title: {
    text: 'Penalty',
  },
  content: [
    {
      text: 'Integer',
    },
    {
      text: 'Used 8 times',
    },
  ],
  buttons: [
    {
      icon: {
        icon: 'trash',
      },
      color: 'assertive',
      link: true,
    },
  ],
};
