import React from 'react';
import AjaxSelect from '.';
import data from './data.json';

export default {
  title: 'BRMS/form/AjaxSelect',
  component: AjaxSelect,
};

const options = [
  {
    value: 'Luke Skywalker',
    detail: 'Created 29 Aug 2020',
    badges: [
      {
        type: 'text',
        content: {
          text: 'Some text',
        },
      },
      {
        type: 'text',
        content: {
          text: 'Some text',
        },
      },
      {
        type: 'text',
        content: {
          text: 'Some text',
        },
      },
      {
        type: 'text',
        content: {
          text: 'Some text',
        },
      },
      {
        type: 'text',
        content: {
          text: 'Some text',
        },
      },
    ],
    tooltip: {
      ordered: true,
      content: [
        { title: 'Some title', detail: 'Some detail' },
        { title: 'Some title', detail: 'Some detail' },
        { title: 'Some title', detail: 'Some detail' },
      ],
    },
  },
  {
    value: 'Luke Skywalker',
    detail: 'Created 29 Aug 2020',
    badges: [
      {
        type: 'text',
        content: {
          text: 'Some text',
        },
      },
      {
        type: 'text',
        content: {
          text: 'Some text',
        },
      },
      {
        type: 'text',
        content: {
          text: 'Some text',
        },
      },
      {
        type: 'text',
        content: {
          text: 'Some text',
        },
      },
      {
        type: 'text',
        content: {
          text: 'Some text',
        },
      },
    ],
    tooltip: {
      collection: true,
      title: 'Collection of',
      content: [
        {
          checked: false,
          label: 'Han Solo',
          value: 'hansolo',
          disabled: true,
        },
        {
          checked: true,
          label: 'Palpatine',
          value: 'sithlord',
          disabled: true,
        },
        {
          checked: false,
          label: 'Windu',
          value: 'badmfr',
        },
      ],
    },
  },
];

const Template = () => (
  <>
    <AjaxSelect placeholder={'Search...'} data={data} />
  </>
);

export const Default = Template.bind({});
