import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Colors, Spacings } from '@components/variables';
import Title from '@components/text/title';
import Icon from '@components/ui/icon';
import { _textColor } from '@components/styles';
import styles from './count.module.scss';

const cx = classNames.bind(styles);

const Count = ({ round, lighten, color, size, className, icon, title }) => {
  const classes = cx(
    {
      count: true,
      round,
      lighten,
    },
    color,
    size,
    className
  );
  const textColor = lighten ? color : _textColor(color);
  return (
    <div className={classes}>
      {icon.icon ? (
        <Icon color={color} {...icon} />
      ) : (
        <Title
          inline
          className={styles.title}
          size={'body'}
          color={textColor}
          {...title}
        />
      )}
    </div>
  );
};

Count.propTypes = {
  /** The text inside the badge */
  title: PropTypes.shape(Title.propTypes),
  /** The icon inside the badge */
  icon: PropTypes.oneOfType([PropTypes.shape(Icon.propTypes), PropTypes.bool]),
  /** The color of the badge */
  color: PropTypes.oneOf(Colors),
  /** The size of the badge */
  size: PropTypes.oneOf(Spacings),
  /** When a circular shape is desirable */
  round: PropTypes.bool,
  /** When the background-color should have an opacity */
  lighten: PropTypes.bool,
  /** Additional styles, mostly passed via another component */
  className: PropTypes.string,
};

Count.defaultProps = {
  title: {},
  size: 's',
  color: 'positive',
  round: false,
  lighten: false,
  className: '',
  icon: false,
};

// Needed for Storybook
Count.displayName = 'Count';

export default Count;
