import React from 'react';
import { Divider } from '@components/ui';
import QuestionCard from '.';

export default {
  title: 'BRMS/card/QuestionCard',
  component: QuestionCard,
};

const Template = (args) => (
  <>
    <QuestionCard {...args} />{' '}
  </>
);

export const Default = Template.bind({});

Default.args = {
  label: {
    text: 'Question (bool)',
  },
  title: {
    text: '1. Do you have any symptoms?',
  },
  content: [
    {
      text: 'Symptoms',
    },
  ],
  buttons: [
    {
      icon: {
        icon: 'trash',
      },
      color: 'assertive',
    },
    {
      icon: {
        icon: 'eye-round',
      },
      color: 'positive',
    },
  ],
};

export const Variants = () => (
  <>
    <QuestionCard
      {...Default.args}
      title={{
        text: 'With multiple content items',
      }}
      content={[
        {
          text: 'Symptoms',
        },
        {
          text: 'Date_symptoms',
        },
      ]}
    />
    <Divider height={20} color={'transparent'} />
    <div id='test' className='u-hide'>
      <h2>This is a tooltip</h2>
    </div>
    <QuestionCard
      {...Default.args}
      title={{
        text: 'With tooltip when clicking eye icon',
      }}
      buttons={[
        {
          icon: {
            icon: 'trash',
          },
          color: 'assertive',
        },
        {
          icon: {
            icon: 'eye-round',
          },
          color: 'positive',
          tooltip: {
            selector: 'test',
          },
        },
      ]}
    />
    <Divider height={20} color={'transparent'} />
    <QuestionCard
      {...Default.args}
      unreachable
      tooltipText={'This is unreachable'}
      title={{
        text: 'With unreachable status warning',
      }}
      buttons={[
        {
          icon: {
            icon: 'trash',
          },
          color: 'assertive',
        },
        {
          icon: {
            icon: 'eye-round',
          },
          color: 'positive',
          tooltip: {
            selector: 'test',
          },
        },
      ]}
    />
  </>
);
