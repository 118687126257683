import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Wrapper from '@components/card/wrapper';
import Tooltip from '@components/ui/tooltip';
import Button from '../button';
import styles from './floating.module.scss';

const cx = classNames.bind(styles);

const Floating = ({ buttons, className, noSticky, filled }) => {
  const classes = cx(
    {
      floating: true,
      noSticky,
      filled,
    },
    className
  );

  const renderButtons = (items) =>
    items.map((item, index) => {
      if (typeof item.action === 'object') {
        const { action } = item;
        delete item.action;
        return (
          <Tooltip key={index} simple={false} actions={action} button={item} />
        );
      }
      return <Button key={index} className={styles.button} {...item} />;
    });
  return (
    <Wrapper size={'s'} color={'light'} className={classes}>
      {renderButtons(buttons)}
    </Wrapper>
  );
};

// Needed for Storybook
Floating.displayName = 'Floating';

Floating.propTypes = {
  /** Buttons for the floating to render */
  buttons: PropTypes.arrayOf(PropTypes.shape(Button.propTypes)).isRequired,
  /** Adds custom class */
  className: PropTypes.string,
  /** When true, removes fixed positioning */
  noSticky: PropTypes.bool,
  /** When true, fills the remaining width */
  filled: PropTypes.bool,
};

Floating.defaultProps = {
  className: '',
  noSticky: false,
};

export default Floating;
