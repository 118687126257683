import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Colors, Spacings } from '@components/variables';
import { Title, Content } from '@components/text';
import Divider from '@components/ui/divider';
import Wrapper from '../wrapper';
import styles from './tooltipCard.module.scss';
import Element from '../../form/element';

const cx = classNames.bind(styles);

const TooltipCard = ({ size, color, className, title, children }) => {
  const classes = cx(
    {
      tooltipCard: true,
    },
    className
  );
  const Components = { Element, Title, Content };

  return (
    <Wrapper size={size} color={color} className={classes}>
      {title && <Title size={'h4'} text={title} />}
      {title && children && <Divider height={10} color={'transparent'} />}
      {children &&
        children.map((child, index) => {
          const Component = Components[child.component];
          if (!Component) {
            return <Content key={index} text={'Component not allowed'} />;
          }
          return <Component key={index} {...child.props} />;
        })}
    </Wrapper>
  );
};

TooltipCard.propTypes = {
  /** The color of the card */
  color: PropTypes.oneOf(Colors),
  /** The size of the card */
  size: PropTypes.oneOf(['no-p', ...Spacings]),
  /** The title of the card */
  title: PropTypes.string,
  /** Rendered children of the card, this can be numbers, strings, elements(components) or an array */
  children: PropTypes.arrayOf(PropTypes.shape({})),
  /** Adds a custom class to the card */
  className: PropTypes.string,
};

TooltipCard.defaultProps = {
  color: 'light',
  size: 'm',
  title: null,
  children: '',
  className: '',
};

// Needed for Storybook
TooltipCard.displayName = 'TooltipCard';

export default TooltipCard;
