import React from 'react';
import TerminationCard from '.';

export default {
  title: 'BRMS/card/TerminationCard',
  component: TerminationCard,
};

const Template = (args) => <TerminationCard {...args} />;

export const Default = Template.bind({});

Default.args = {
  title: {
    text: 'Termination',
  },
};
