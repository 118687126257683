import NestedForm from '@stimulus-components/rails-nested-form';

export default class extends NestedForm {
  static targets = ['search'];

  connect() {
    super.connect();
  }

  add(e) {
    super.add(e);
    if (this.hasSearchTarget) {
      this.searchTarget.disabled = true;
    }
  }

  remove(e) {
    super.remove(e);
    if (this.hasSearchTarget) {
      this.searchTarget.disabled = true;
    }
  }

  addInBetween(event) {
    // Disable clicking on the rmv-column if the 'Add row' button is disabled
    if (
      event.currentTarget.tagName === 'RMV-COLUMN' &&
      event.currentTarget.querySelector('rmv-button').disabled
    ) {
      return;
    }
    if (this.hasSearchTarget) {
      this.searchTarget.disabled = true;
    }
    event.preventDefault();
    const template = this.templateTarget;
    const div = document.createElement('div');
    div.innerHTML = template.innerHTML.replace(
      /NEW_RECORD/g,
      new Date().getTime()
    );
    const target = event.currentTarget.closest(
      `rmv-row${this.wrapperSelectorValue}`
    );
    const table = target.closest('rmv-table');
    table.insertBefore(div.firstElementChild, target);

    // Update the sequence number
    const sequenceInputs = table.querySelectorAll(
      'input[data-sortable-target]'
    );
    sequenceInputs.forEach((input, index) => {
      input.value = index;
    });
  }
}
