import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';

import InformationBranch from './_informationbranch';

import styles from './informationtree.module.scss';

const cx = classNames.bind(styles);

const InformationTree = (data) => {
  const classes = cx({
    informationtree: true,
  });
  return (
    <ul className={classes}>
      <li>
        <InformationBranch {...data} />
      </li>
    </ul>
  );
};

InformationTree.propTypes = {
  /** The action of an item in the tree */
  action: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  /** Whether the subtree should be visible on init */
  active: PropTypes.bool,
  /** Input type (i.e. checkbox) */
  type: PropTypes.string,
  /** Button text */
  button: PropTypes.string,
  /** The text of the item in the tree */
  text: PropTypes.string.isRequired,
  /** If there is a view option (conflict/changes) */
  view: PropTypes.bool,
  /** Input name */
  name: PropTypes.string,
  /** Input value */
  value: PropTypes.string,
  /** All the options in a tree branch (child) . */
  childs: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
      active: PropTypes.bool,
      text: PropTypes.string.isRequired,
      childs: PropTypes.arrayOf(PropTypes.shape({})),
      view: PropTypes.bool,
      button: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.string,
    })
  )
};

// Needed for Storybook
InformationTree.displayName = 'InformationTree';

export default InformationTree;
