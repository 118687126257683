import React from 'react';
import { Title } from '@components/text';
import Modal from '.';

export default {
  title: 'BRMS/modal/Modal',
  component: Modal,
};

const Template = (args) => (
  <>
    <div id='hidden-div'>
      <Title text={'I am hidden'} />
    </div>
    <Modal {...args} />
  </>
);

export const Default = Template.bind({});
Default.args = {
  trigger: {
    text: 'Open modal',
  },
  title: {
    text: 'Modal title',
  },
  inverted: true,
  id: 'hidden-div',
};
