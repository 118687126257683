import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  // DEV: REFACTOR 'toggleable' -> 'element' when fully converted to RMV
  static targets = ['toggleable', 'element'];

  toggle() {
    this.elementTargets.forEach((element) => {
      element.hidden = !element.hidden;
    });
  }

  // DEV: Check if SHOW and HIDE methods are really needed when fully converted to RMV
  show() {
    const el = this.element.querySelector('brms-card[event-id="toggleable"]');
    if (el != null) {
      el.removeAttribute('hidden');
    }
    // As soon as ReMark Components support this, use the line below and remove the above lines
    // this.toggleableTarget.removeAttribute('hidden');
  }

  hide() {
    const el = this.element.querySelector('brms-card[event-id="toggleable"]');
    if (el != null) {
      el.setAttribute('hidden', 'true');
    }
    // As soon as ReMark Components support this, use the line below and remove the above lines
    // this.toggleableTarget.setAttribute('hidden', 'true');
  }
}
