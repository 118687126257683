import React from 'react';
import PropTypes from 'prop-types';
import Title from '@components/text/title';
import Icon from '@components/ui/icon';
import Divider from '@components/ui/divider';
import Button from '@components/button/button';
import { Spacings } from '@components/variables';
import classNames from 'classnames/bind';
import Wrapper from '../wrapper';
import styles from './termination.module.scss';

const cx = classNames.bind(styles);

const TerminationCard = ({ size, title, buttons, className }) => {
  const cardClasses = cx({ wrapper: true }, className);
  return (
    <Wrapper className={cardClasses} size={size}>
      <Icon icon={'stop'} color={'assertive'} />
      <Divider width={10} color={'transparent'} />
      <Title
        color={'assertive'}
        size={'h4'}
        className={styles.title}
        {...title}
      />
      {buttons && (
        <div className={styles.buttons}>
          {buttons.map((button, index) => (
            <Button key={index} size={'s'} link {...button} />
          ))}
        </div>
      )}
    </Wrapper>
  );
};

TerminationCard.propTypes = {
  /** Padding of the card */
  size: PropTypes.oneOf(Spacings),
  /** The content of the card */
  title: PropTypes.shape(Title.propTypes),
  /** All props automatically passed when used inside a diagram */
  diagram: PropTypes.bool,
};

TerminationCard.defaultProps = {
  size: 's',
  title: {},
  diagram: false,
};

// Needed for Storybook
TerminationCard.displayName = 'TerminationCard';

export default TerminationCard;
